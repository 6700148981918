/*!
 * SlickNav Responsive Mobile Menu v1.0.10
 * (c) 2016 Josh Cope
 * licensed under MIT
 */
 .slicknav_btn{position:relative;display:block;vertical-align:middle;float:right;padding:0.438em 0.625em 0.438em 0.625em;line-height:1.125em;cursor:pointer;}
 .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar{margin-top:0.188em;}
 .slicknav_menu{*zoom:1;}
 .slicknav_menu .slicknav_icon:before{background:transparent;display:block;content:"";position:absolute;}
 .slicknav_menu .slicknav_no-text{margin:0;}
 .slicknav_menu .slicknav_icon-bar{display:block;}
 .slicknav_menu:before{content:" ";display:table;}
 .slicknav_menu:after{content:" ";display:table;clear:both;}
 .slicknav_nav{clear:both;}
 .slicknav_nav ul{display:block;}
 .slicknav_nav li{display:block;position:relative;}
 .slicknav_nav .slicknav_arrow{font-size:0.8em;margin:0 0 0 0.4em;}
 .slicknav_nav .slicknav_item{cursor:pointer;}
 .slicknav_nav .slicknav_item a{display:inline;}
 .slicknav_nav .slicknav_row{display:block;}
 .slicknav_nav Link{display:block;}
 .slicknav_nav .slicknav_parent-link a{display:inline;}
 .slicknav_brand{float:left;}
 .slicknav_menu{font-size:16px;box-sizing:border-box;background:#4c4c4c;padding:5px;}
 .slicknav_menu *{box-sizing:border-box;}
 .slicknav_menu .slicknav_menutxt{color:#9babb6;margin:0 5px;}
 .slicknav_nav{color:#fff;margin:0;padding:0;font-size:0.875em;list-style:none;overflow:hidden;}
 .slicknav_nav ul{list-style:none;overflow:hidden;padding:0;margin:0 0 0 20px;}
 .slicknav_nav .slicknav_row{    /* padding: 5px 10px; */
 margin:2px 5px;}
 .slicknav_nav .slicknav_row:hover{-webkit-border-radius:6px;-moz-border-radius:6px;border-radius:6px;background:#ccc;color:#fff;}
 .slicknav_nav a{padding:5px 10px;margin:2px 5px;text-decoration:none;color:#fff;}
 .slicknav_nav a:hover{-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;background:#ccc;color:#222;}
 .slicknav_nav .slicknav_txtnode{margin-left:15px;}
 .slicknav_nav .slicknav_item a{padding:0;margin:0;}
 .slicknav_nav .slicknav_parent-link a{padding:0;margin:0;}
 .slicknav_brand{color:#fff;font-size:18px;line-height:30px;padding:7px 12px;height:44px;}
 
 /*===== mobile menu slicknav =====*/
 .mobile_menu{position:absolute;right:10px;width:96%;z-index:99;}
 .slicknav_menu{background:transparent;margin-top:10px;}
 .slicknav_menu .slicknav_icon-bar{background-color:#ffffff;height:3px;margin:5px 0;-webkit-transition:all 0.3s ease 0s;-o-transition:all 0.3s ease 0s;transition:all 0.3s ease 0s;width:30px;position:relative;}
 .slicknav_menu{margin:0;padding:0;}
 .slicknav_nav{background:#fff;float:right;padding:20px;width:100%;border-top:1px solid #f2f2f2;top:60px;position:fixed;}
 .slicknav_nav a{font-size:12px;font-weight:400;color:#092b4d;text-transform:capitalize;line-height:2;text-align:left;text-transform:uppercase;font-weight:500;font-family:'Roboto',sans-serif;}
 .slicknav_nav .slicknav_arrow{float:right;}
 .slicknav_nav .slicknav_row:hover,
 .slicknav_nav .slicknav_row:hover .slicknav_arrow{border-radius:0;background-color:#F79960;background-color:transparent;color:#000;}
 .slicknav_btn{cursor:pointer;position:relative;z-index:99;border:none;top:23px;background:0;border-radius:3px;padding:0;right:30px;}
 .slicknav_menu .slicknav_icon{position:relative;margin:0;padding:0;}
 .slicknav_nav .slicknav_arrow{float:right;font-size:22px;position:absolute;top:-8px;right:10px;}
 .slicknav_menu .slicknav_nav a:hover{background:transparent;color:#dca73a;}
 .slicknav_collapsed .ti-close{display:none;}
 .slicknav_collapsed .slicknav_collapsed .ti-close{display:inline-block;}
 .slicknav_collapsed .slicknav_collapsed .ti-menu-alt{display:none;}