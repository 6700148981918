.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: 40rem;
  }
  
  .loader-circle {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  /* Media Query for the loader  */
  @media (max-width:980px) {
    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0 25rem;
      margin-top: 30px;
      
    }
    .loader-circle {
      border: 8px solid #f3f3f3;
      border-top: 8px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;

    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  @media (max-width:700px) {
    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0 15rem;
    }
    .loader-circle {
      border: 8px solid #f3f3f3;
      border-top: 8px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;

    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  @media (max-width:500px) {
    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0 10rem;
    }
    .loader-circle {
      border: 8px solid #f3f3f3;
      border-top: 8px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;

    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }