*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.PrivacypolicyMain{
    padding: 10px;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 1px solid rgb(192, 186, 186);
    border-radius: 10px;
    width: 73%;
    margin: auto;
    margin-top: 70px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: white;
    margin-bottom: 30px;
}
.PrivacypolicyMainHeading{
    background-color: rgb(219, 39, 39);
    color: white;
    text-align: center;
    padding: 0;
   font-family: Arial, Helvetica, sans-serif;
}
.PrivacypolicyMainPara{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.PrivacypolicyMainParah4{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 29px;
}