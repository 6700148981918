/*Import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
/* Import third party CSS library */
@import url(vendor/bootstrap.min.css);
@import url(vendor/slicknav.css);
@import url(vendor/fontawesome-all.min.css);
@import url(vendor/themify-icons.css);

/* GENERAL */
body {
	font-family: "Noto Sans", sans-serif;
	font-weight: normal;
	font-style: normal;
	background: #fff;
}
.marquee-container {
	overflow: hidden;
	padding-top: 9px;
	margin-left: 10px;
	width: 550px;
  }
  
  .marquee-content {
	width: max-content; /* Adjust width to fit the entire title */
	display: inline-block;
	white-space: nowrap;
	animation: marqueeAnimation 20s linear infinite;
	gap: 30px;
  }
  
  @keyframes marqueeAnimation {
	0% {
	  transform: translateX(0%);
	}
	100% {
	  transform: translateX(-100%);
	}
  }
	@keyframes marquee {
	0% {
	  transform: translateX(0%); /* Move title out of view initially */
	}
	100% {
	  transform: translateX(-100%); /* Move title back into view */
	}
  }

h1,
h2,
h3,
h4,
h5,
h6,
a.read-more,
.entry-meta,
.readmore-btn,
.widget-taber a.nav-link {
	font-family: "Noto Sans", sans-serif;
	margin-top: 0px;
	font-style: normal;
	font-weight: 900;
	text-transform: normal
}

.entry-meta.font-x-small {
	font-weight: 600;
}

p {
	font-family: "Noto Sans", sans-serif;
	font-size: 16px;
	margin-bottom: 15px;
	font-weight: normal
}

video {
	max-width: 100%;
}

.img {
	max-width: 100%;
	-webkit-transition: all .2s ease-out 0s;
	-moz-transition: all .2s ease-out 0s;
	-ms-transition: all .2s ease-out 0s;
	-o-transition: all .2s ease-out 0s;
	transition: all .2s ease-out 0s
}

.fix {
	overflow: hidden
}

.clear {
	clear: both
}

a,
.button {
	-webkit-transition: all .2s ease-out 0s;
	-moz-transition: all .2s ease-out 0s;
	-ms-transition: all .2s ease-out 0s;
	-o-transition: all .2s ease-out 0s;
	transition: all .2s ease-out 0s
}

a:focus,
.button:focus {
	text-decoration: none;
	outline: none
}

a {
	color: #171616;
}

a:hover {
	color: #f04646;
}

a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	text-decoration: none
}

.transition-02s,
.transition-02s:hover {
	-webkit-transition: all .2s ease-out 0s;
	-moz-transition: all .2s ease-out 0s;
	-ms-transition: all .2s ease-out 0s;
	-o-transition: all .2s ease-out 0s;
	transition: all .2s ease-out 0s;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
	outline: 0
}

input:focus::-moz-placeholder {
	opacity: 0;
	-webkit-transition: .4s;
	-o-transition: .4s;
	transition: .4s
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit
}

ul {
	margin: 0px;
	padding: 0px
}

li {
	list-style: none
}

hr {
	border-bottom: 1px solid #eceff8;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0
}

img {
	max-width: 100%;
}

ul {
	padding: 0;
	margin: 0;
}

.font-size-1rem {
	font-size: 1rem;
}

.readmore-btn:hover i {
	padding-left: 6px;
}

.NewsDetailsVideos {
	width: 800px;
	height: 500px;
	background-color: #edeff1;
}

.boxed-btn {
	background: #fff;
	display: inline-block;
	padding: 18px 44px;
	font-family: 'Nunito', sans-serif;
	font-size: 14px;
	font-weight: 400;
	border: 0;
	border: 1px solid;
	letter-spacing: 3px;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer
}

.boxed-btn:hover {
	color: #fff !important;
	border: 1px solid
}

.boxed-btn:focus {
	outline: none
}

.boxed-btn.large-width {
	width: 220px
}

[data-overlay] {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center
}

[data-overlay]::before {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	content: ""
}

[data-opacity="1"]::before {
	opacity: 0.1
}

[data-opacity="2"]::before {
	opacity: 0.2
}

[data-opacity="3"]::before {
	opacity: 0.3
}

[data-opacity="4"]::before {
	opacity: 0.4
}

[data-opacity="5"]::before {
	opacity: 0.5
}

[data-opacity="6"]::before {
	opacity: 0.6
}

[data-opacity="7"]::before {
	opacity: 0.7
}

[data-opacity="8"]::before {
	opacity: 0.8
}

[data-opacity="9"]::before {
	opacity: 0.9
}

::placeholder {
	color: #838383
}

.section-padding {
	padding-top: 120px;
	padding-bottom: 120px
}

.font-x-small {
	font-size: 11px !important;
}

.font-small {
	font-size: 13px !important;
}

.font-medium {
	font-size: 15px !important;
}

.font-large {
	font-size: 20px !important;
}

.separator {
	border-top: 1px solid #f2f2f2
}

.owl-carousel .owl-nav div {
	background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
	height: 40px;
	left: 20px;
	line-height: 40px;
	font-size: 22px;
	color: #646464;
	opacity: 1;
	visibility: visible;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.2s ease 0s;
	width: 40px
}

.owl-carousel .owl-nav div.owl-next {
	left: auto;
	right: -30px
}

.owl-carousel .owl-nav div.owl-next i {
	position: relative;
	right: 0;
	top: 1px
}

.owl-carousel .owl-nav div.owl-prev i {
	position: relative;
	right: 1px;
	top: 0px
}

.owl-carousel:hover .owl-nav div {
	opacity: 1;
	visibility: visible
}

.owl-carousel:hover .owl-nav div:hover {
	color: #fff;
	background: #ff3500
}

.btn {
	background: #ff656a;
	text-transform: uppercase;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 0;
	margin-bottom: 0;
	padding: 27px 44px;
	border-radius: 5px;
	min-width: 200px;
	margin: 10px;
	cursor: pointer;
	transition: color 0.4s linear;
	position: relative;
	z-index: 1;
	border: 0;
	overflow: hidden;
	margin: 0
}

.btn::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #e6373d;
	z-index: 1;
	border-radius: 5px;
	transition: transform 0.5s;
	transition-timing-function: ease;
	transform-origin: 0 0;
	transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
	transform: scaleX(0)
}

.btn:hover::before {
	transform: scaleX(1);
	color: #fff !important;
	z-index: -1
}

.btn.focus,
.btn:focus {
	outline: 0;
	box-shadow: none
}

#scrollUp {
	height: 30px;
	width: 30px;
	right: 20px;
	bottom: 20px;
	color: #fff;
	font-size: 14px;
	text-align: center;
	border-radius: 15px;
	padding-top: 5px;
	line-height: 23px;
}

#srollbottom {
	height: 30px;
	width: 30px;
	right: 20px;
	bottom: 20px;
	color: #fff;
	font-size: 14px;
	text-align: center;
	border-radius: 15px;
	padding-top: 5px;
	line-height: 23px;
}

#srollbottom:hover {
	color: rgb(236, 226, 226);
}

#scrollUp:hover {
	color: #fff
}

.sticky-bar {
	left: 0;
	margin: auto;
	position: fixed;
	top: 0;
	width: 100%;
	-webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
	z-index: 9999;
	-webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
	background: #fff;
}

.preloader {
	background-color: #f7f7f7;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999;
	-webkit-transition: .6s;
	-o-transition: .6s;
	transition: .6s;
	margin: 0 auto
}

.preloader img.jump {
	max-height: 100px;
}

.align-center-vertical {
	margin-top: auto;
	margin-bottom: auto;
}

.text-limit-2-row {
	overflow: hidden !important;
	display: -webkit-box !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical;
	line-height: 30px;
}

.text-limit-3-row {
	overflow: hidden !important;
	display: -webkit-box !important;
	-webkit-line-clamp: 3 !important;
	-webkit-box-orient: vertical;
}

.img-hover-scale {
	overflow: hidden;
	position: relative;
}

.img-hover-scale img {
	-webkit-transition: -webkit-transform .5s;
	transition: -webkit-transform .5s;
	-o-transition: transform .5s;
	transition: transform .5s;
	transition: transform .5s, -webkit-transform .5s;
}

.img-hover-scale:hover img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: -webkit-transform .5s;
	transition: -webkit-transform .5s;
	-o-transition: transform .5s;
	transition: transform .5s;
	transition: transform .5s, -webkit-transform .5s;
}

.img-hover-slide {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	min-height: 280px;
	overflow: hidden;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
}

.entry-header-3 .img-hover-slide {
	min-height: 380px;
}


.post-thumb:hover .img-hover-slide,
.single-header-2:hover .img-hover-slide {
	background-position: 30%;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
}

.post-block-list {
	max-height: 601px;
	/* Set a maximum height for the container */
	overflow-y: auto;
	margin: 10px auto;
}

.img-link {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
}

.thumb-overlay {
	position: relative;
}

.thumb-overlay::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), to(rgba(0, 0, 0, .8)));
	background: -webkit-linear-gradient(top, transparent 50%, rgba(0, 0, 0, .8) 100%);
	background: -o-linear-gradient(top, transparent 50%, rgba(0, 0, 0, .8) 100%);
	background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, .8) 100%);
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease
}

.thumb-overlay:hover::before {
	background-color: rgba(0, 0, 0, .2)
}

.transition-ease-04 {
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
}

.top-right-icon {
	position: absolute;
	bottom: 15px;
	right: 15px;
	border-radius: 5px;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	z-index: 3;
	color: #fff;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition-duration: 0.4s;
	-ms-transition: all 0.4s ease;
}

.top-right-icon i {
	top: 2px;
}

.img-hover-scale:hover .top-right-icon,
.img-hover-slide:hover .top-right-icon {
	margin-top: -3px;
	-webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.18), 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition-duration: 0.4s;
	-ms-transition: all 0.4s ease;
}

.hover-box-shadow {
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition-duration: 0.4s;
	-ms-transition: all 0.4s ease;
}

.hover-box-shadow:hover {
	-webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.18), 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition-duration: 0.4s;
	-ms-transition: all 0.4s ease;
}

.letter-background {
	line-height: 1.2;
	color: rgba(0, 0, 0, .07);
	z-index: 1;
	position: absolute;
	top: 50%;
	left: -15px;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-moz-user-select: none;
	user-select: none;
	pointer-events: none;
	text-transform: uppercase;
	font-size: 4rem;
	font-weight: 900;
}

.font-weight-ultra {
	font-weight: 900;
}

.scroll-progress {
	height: 3px;
	width: 0;
	z-index: 9999999;
	position: absolute;
	bottom: -3px;
}


/*cursor*/
#off-canvas-toggle,
.user-account,
.subscribe,
.search-clos,
button.slick-arrow,
.off-canvas-close,
.search-button,
.slick-dots button,
.slider-post-thumb,
button.search-icon {
	cursor: pointer;
}

/*Gradient*/
.gradient-bg-1 {
	background: rgb(226, 70, 92);
	background: -webkit-linear-gradient(left, rgba(226, 70, 92, 1) 0%, rgba(254, 65, 65, 1) 50%, rgba(255, 185, 87, 1) 100%);
	background: -o-linear-gradient(left, rgba(226, 70, 92, 1) 0%, rgba(254, 65, 65, 1) 50%, rgba(255, 185, 87, 1) 100%);
	background: linear-gradient(to right, rgba(226, 70, 92, 1) 0%, rgba(254, 65, 65, 1) 50%, rgba(255, 185, 87, 1) 100%);
}

/*Header*/
h1.logo-text {
	font-size: 20px;
	font-weight: 600;
	margin-left: 5px;
	margin-bottom: 0;
	line-height: 1;
	margin-top: 7px;
}

#off-canvas-toggle span,
#off-canvas-toggle span::before,
#off-canvas-toggle span::after {
	background: #353c49;
	display: inherit;
	height: 2px;
	position: relative;
	width: 15px;
}

#off-canvas-toggle span::before,
#off-canvas-toggle span::after {
	content: "";
	left: 0;
	position: absolute;
	right: 0;
}

#off-canvas-toggle span::before {
	top: -8px;
	width: 15px;
}

#off-canvas-toggle span::after {
	top: -4px;
	width: 15px;
}

#off-canvas-toggle p {
	display: inline;
	font-size: 12px;
	margin: 0 0 0 5px;
}

.off-canvas-close {
	background: none;
	border: 0;
	width: 30px;
	height: 30px;
	position: absolute;
	right: 10px;
	top: 10px;
}

#datetime {
	min-width: 160px;
	margin-bottom: -8px;
	margin-left: 15px;
}

.vline-space {
	height: 15px;
	width: 1px;
	background: #cbd3d7;
	margin: 0 10px -2px 10px;
}

button.search-icon {
	background: none;
	padding: 0;
	border: 0;
	font-size: 16px;
	width: 30px;
}

.top-bar ul.header-social-network li a {
	padding: 0px;
	font-size: 12px;
}

button.subscribe {
	border: 1px solid;
	background: none;
	margin: 0 0 0 15px;
	border-radius: 30px;
	padding: 5px 15px;
	font-weight: bold;
}

.user-account .ti-user {
	margin-right: 2px;
}

.dropdown-menu {
	border-radius: 5px;
}

#userMenuDropdow.dropdown-menu,
#langMenuDropdow.dropdown-menu {
	background: #ffff;
	border: 0;
	padding: 5px 15px;
	margin-top: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

#langMenuDropdow.dropdown-menu {
	min-width: 110px;
}

#userMenuDropdow.dropdown-menu a,
#langMenuDropdow.dropdown-menu a {
	font-size: 12px;
	padding: 7px 0;
}

#userMenuDropdow.dropdown-menu a i,
#langMenuDropdow.dropdown-menu a i {
	margin-right: 8px;
	color: #b0b8cc;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: .25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

form.menu-search-form input {
	width: 100%;
}

/*Offcanvas Sidebar*/
.off-canvas-toggle-cover {
	position: fixed;
	top: 20px;
	right: 15px;
	z-index: 999;
}

#sidebar-wrapper {
	background: #fff;
	left: 0;
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	width: 0;
	z-index: 1009;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	top: 0;
	box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);
}

.position-midded {
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
	left: 50%;
}

#sidebar-wrapper.position-right {
	right: 0;
	left: unset;
}

.canvas-opened #sidebar-wrapper {
	width: 350px;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.canvas-opened #off-canvas-toggle span {
	background: none;
}

.canvas-opened #off-canvas-toggle span::after {
	top: -4px;
	width: 15px;
	transform: rotate(-45deg);
}

.canvas-opened #off-canvas-toggle span::before {
	top: -4px;
	width: 15px;
	transform: rotate(45deg);
}

.sidebar-inner {
	padding: 30px;
	display: table;
	height: 100%;
}

.dark-mark {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1003;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.55);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-transition: visibility 0s linear .3s, opacity .3s ease, -webkit-transform .3s ease;
	/* transition: visibility 0s linear .3s,opacity .3s ease,-webkit-transform .3s ease; */
	/* transition: visibility 0s linear .3s,transform .3s ease,opacity .3s ease; */
	transition: visibility 0s linear .3s, transform .3s ease, opacity .3s ease, -webkit-transform .3s ease;
}

.canvas-opened .dark-mark {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity .3s ease, -webkit-transform .3s ease;
	transition: opacity .3s ease, -webkit-transform .3s ease;
	transition: transform .3s ease, opacity .3s ease;
	transition: transform .3s ease, opacity .3s ease, -webkit-transform .3s ease;
}

/*Search form*/
.main-search-form {
	height: 0;
	opacity: 0;
	visibility: hidden;
}

.open-search-form .main-search-form {
	height: 100%;
	opacity: 1;
	visibility: visible;
}

.main-search-form .search-form label {
	width: 100%;
}

.main-search-form .search-form input {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	background-color: transparent;
	border: none;
	border-radius: 999px;
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	margin: 0;
	padding: 9px 0 9px 50px;
	text-align: left;
	width: 100%;
	background: #fff;
	border: 1px solid #dfe0e6;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
}

.main-search-form .search-form-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 23px;
	margin-top: -3px;
	opacity: 0.6;
}

.main-search-form .search-switch {
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
	margin-top: -3px;
	line-height: 1;
	border-left: 1px solid #dfe0e6;
	padding-left: 15px;
	font-size: 14px;
}

/*Main header*/
.main-header .main-nav ul li a {
	position: relative;
	color: #4d4d4d;
	font-weight: 700;
	padding: 0;
	display: block;
	font-size: 14px;
	-webkit-transition: all .2s ease-out 0s;
	-moz-transition: all .2s ease-out 0s;
	-ms-transition: all .2s ease-out 0s;
	-o-transition: all .2s ease-out 0s;
	transition: all .2s ease-out 0s;
	font-family: 'Nunito', sans-serif;
	line-height: 1.5;
	line-height: 60px;
}

.main-header .main-nav ul li>a::after {
	content: "";
	width: 0;
	height: 2px;
	position: absolute;
	left: 50%;
	top: -1px;
	opacity: 0.5;
	transform: translateX(-50%);
}

.main-header .main-nav ul li.mega-menu-item a::after,
.main-header .main-nav ul li ul a::after {
	content: none;
}

.main-header .main-nav ul li.current-menu-item>a::after,
.main-header .main-nav ul li:hover a::after {
	width: 50px;
	-webkit-transition: all .2s ease-out 0s;
	-moz-transition: all .2s ease-out 0s;
	-ms-transition: all .2s ease-out 0s;
	-o-transition: all .2s ease-out 0s;
	transition: all .2s ease-out 0s;
}

.main-header .main-nav ul li.menu-item-has-children>a {
	padding-right: 15px;
}

.main-header .main-nav ul li.menu-item-has-children>a::before {
	content: "\e64b";
	font-family: 'themify';
	font-size: 8px;
	display: inline-block;
	padding-left: 5px;
	position: absolute;
	right: 0;
}

.main-header .main-nav ul ul.sub-menu {
	text-align: left;
	position: absolute;
	min-width: 170px;
	background: #fff;
	left: 0;
	top: 120%;
	visibility: hidden;
	opacity: 0;
	padding: 17px 0;
	-webkit-transition: all .2s ease-out 0s;
	-moz-transition: all .2s ease-out 0s;
	-ms-transition: all .2s ease-out 0s;
	-o-transition: all .2s ease-out 0s;
	transition: all .2s ease-out 0s;
}

.main-header .main-nav ul ul.sub-menu {
	display: block;
	padding-left: 15px;
	border-radius: 0 0 5px 5px;
}

.main-header .main-nav ul ul.sub-menu>li>a {
	font-weight: 500;
	line-height: 35px;
}

.main-header .main-nav ul ul.sub-menu>li>a:hover {
	background: none
}

.header-area .header-top .header-info-right .header-social a i {
	-webkit-transition: all .4s ease-out 0s;
	-moz-transition: all .4s ease-out 0s;
	-ms-transition: all .4s ease-out 0s;
	-o-transition: all .4s ease-out 0s;
	transition: all .4s ease-out 0s;
	transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	-o-transform: rotateY(0deg)
}

.header-area .header-top .header-info-right .header-social a:hover i {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	-o-transform: rotateY(180deg)
}

.main-header ul>li:hover>ul.sub-menu {
	visibility: visible;
	opacity: 1;
	top: 100%
}

.main-header .main-nav ul li h6 a {
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.2;
}

ul.slicknav_nav {
	-webkit-column-count: 4;
	-moz-column-count: 4;
	column-count: 4;
}

.logo-mobile {
	max-width: 100px;
	z-index: 500;
	position: relative;
}

/* Mega menu */
.main-menu {
	list-style-type: none;
	margin: 0;
	height: 100%;
	align-items: center;
	width: 300px;
	margin-right: 200px;
}

.BsFacebook {
	color: #0c8fed;
	font-size: 1.5rem;
}

.AiOutlineTwitter {
	color: #0c8fed;
	font-size: 1.5rem;
}

.FcGoogle {
	font-size: 1.5rem;
}

.instagram-gradient {
	color: #bc2a8d;
	/* Set your desired color here */
	font-size: 1.5rem;
	/* Adjust the font size if needed */
}

@keyframes gradientAnimation {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.main-menu>li {
	height: 100%;
	position: relative;
	display: inline-block;
	padding: 0 20px;
}

.main-menu>li.mega-menu-item {
	position: static;
}

.sub-mega-menu {
	flex-wrap: wrap;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	width: 100%;
	display: none;
	box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.05);
	border-top: 1px solid #f4f5f9;
	border-radius: 0 0 5px 5px;
}

.main-menu>li:hover>.sub-mega-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
}

.sub-mega-menu .nav {
	width: 180px;
	padding: 30px 0 0 30px;
}

.sub-mega-menu .tab-content {
	width: calc(100% - 180px);
}

.sub-mega-menu .nav-pills .nav-link {
	font-size: 12px;
	line-height: 1.8;
	color: #222;
	border-radius: 30px;
	padding: 5px 20px;
	text-align: left;
	border-radius: 50px;
}

.sub-mega-menu .nav-pills .nav-link.active,
.sub-mega-menu .show>.nav-pills .nav-link {
	color: #fff;
	background-color: #7f8991;
}

.sub-mega-menu .tab-content .tab-pane {
	padding: 25px 50px 35px 30px;
}

.main-header .main-nav ul li .sub-mega-menu.sub-menu-list {
	padding: 15px;
	line-height: 35px;
}

.main-header .main-nav ul li .sub-mega-menu.sub-menu-list a {
	line-height: 35px;
	font-weight: 500;
}

.main-header .main-nav ul li.mega-menu-item .tab-content a {
	padding: 0;
	text-transform: none;
}

.header-sticky.sticky-bar.sticky .header-flex {
	justify-content: space-between;

}

ol ol,
ol ul,
ul ol,
ul ul {
	margin-bottom: 0;
	gap: 0px;
	display: flex;
	border-radius: 50%;
}

.header-sticky.sticky-bar.sticky .header-btn .get-btn {
	padding: 20px 20px
}

.header-area .slicknav_btn {
	top: -51px;
	right: 17px
}

.slicknav_menu .slicknav_nav a:hover {
	background: transparent;
}

.slicknav_menu {
	background: transparent;
}

.mobile_menu {
	position: fixed;
	right: 0px;
	width: 100%;
	z-index: 99;
}

.header-sticky.sticky-bar.sticky .header-right-btn {
	display: none !important
}

.header-sticky.sticky-bar.sticky .sticky-logo {
	display: block !important
}

.sticky-logo {
	display: none !important
}

.sticky-logo.info-open {
	padding: 12px
}

/*Header layouts*/
.main-header {
	position: relative;
	z-index: 1002;
}

.main-header #userMenuDropdow.dropdown-menu,
.main-header #userMenuDropdow.dropdown-menu {
	margin-top: 15px;
}

.header-style-1 {
	background: #ffffff;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05)
}

.header-style-1 .header-bottom,
.header-style-2 .header-bottom,
.header-style-2.header-style-3 .header-bottom {
	border-top: 1px solid #f4f5f9;
	height: 60px;
	line-height: 60px;
}

.header-style-1 .search-button,
.header-style-2 .search-button {
	position: absolute;
	right: 15px;
	top: 0;
	line-height: 70px;
	z-index: 300;
}

.widget-title {
	padding-left: 12px;
	padding-top: 15px;
}

.header-style-1 .top-bar,
.header-style-2 .topbar-inner {
	border-bottom: 1px solid #f4f5f9;
}

.header-style-2 .off-canvas-toggle-cover {
	right: 15px;
	left: unset;
}

.header-style-2.header-style-3 .topbar-inner {
	border: 0;
}

.open-search-form .header-style-2 .off-canvas-toggle-cover {
	right: 80px;
}

.header-style-2 .header-bottom {
	border: none;
}

.header-style-2 ion-icon {
	font-size: 17px;
	margin-bottom: -2px;
}

.header-style-2 input.search_field,
.offcanvas-sidebar input.search_field {
	border: 1px solid #eee;
	height: 30px;
	border-radius: 30px;
	background: #f7f8f9;
	font-size: 12px;
	width: 320px;
	position: relative;
	padding: 0 40px 0 20px;
}

.header-style-2 .search-form .search-icon,
.offcanvas-sidebar .search-form .search-icon {
	position: absolute;
	right: 10px;
	font-size: 13px;
	top: 6px;
	opacity: 0.5;
}

.offcanvas-sidebar input.search_field {
	width: 100%;
	height: 40px;
	line-height: 40px;
}

.offcanvas-sidebar .search-form .search-icon {
	top: 10px;
	font-size: 16px;
}

.tools-icon {
	text-align: right;
}

.tools-icon a {
	padding: 0 7px;
	position: relative;
}

span.notification {
	position: absolute;
	color: #fff;
	font-size: 10px;
	font-weight: 500;
	top: -10px;
	right: 0;
	border-radius: 50%;
	display: block;
	width: 14px;
	height: 14px;
	text-align: center;
	line-height: 14px;
}

/*Page layouts*/
.content-404 {
	margin: 0 auto;
}

.content-404 h1 {
	font-size: 8rem;
}

.content-404 input#search {
	max-width: 450px;
	margin: 0 auto;
	border-radius: 40px;
	padding-left: 25px;
}

.footer-area .footer-logo {
	margin-bottom: 40px
}

.footer-area .footer-pera p {
	color: #a8a8a8;
	font-size: 15px;
	margin-bottom: 50px;
	line-height: 1.8
}

.footer-area .footer-pera.footer-pera2 p {
	padding: 0
}

p {
	font-family: "Noto Sans", sans-serif;
	font-size: 16px;
	margin-bottom: 15px;
	font-weight: 400;
	color: #333333;
}

.footer-area .footer-tittle h4 {
	color: #fff;
	font-size: 20px;
	margin-bottom: 29px;
	font-weight: 500;
	text-transform: capitalize
}

.footer-area {
	color: #9babb6
}

.footer-area li.cat-item {
	padding: 5px;
}

.cat-item-2 {
	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 3px;
	padding-top: 3px;
	/* background-color: white; */
	border-radius: 10px;
}

.footer-area .footer-tittle ul li {
	color: #012f5f;
	margin-bottom: 15px
}

.footer-area .footer-tittle ul li a {
	color: #333333;
	font-weight: 300
}

.footer-area .footer-tittle ul li a:hover {
	padding-left: 5px
}

.footer-area .footer-form form {
	position: relative
}

.footer-area .footer-form form input {
	width: 100%;
	height: 43px;
	border: 0;
	border-bottom: 1px solid #403f3f;
	background: none
}

.footer-area .footer-form form .form-icon button {
	position: absolute;
	top: 0;
	right: -20px;
	background: none;
	border: 0;
	cursor: pointer;
	padding: 13px 22px;
	line-height: 1
}

.footer-area .footer-social a i {
	color: #222222;
	margin-right: 12px;
	font-size: 19px;
	-webkit-transition: all .4s ease-out 0s;
	-moz-transition: all .4s ease-out 0s;
	-ms-transition: all .4s ease-out 0s;
	-o-transition: all .4s ease-out 0s;
	transition: all .4s ease-out 0s
}

.footer-area .footer-social a i:hover {
	box-shadow: 0 -3px 0 0 rgba(24, 24, 24, 0.15) inset, 0 3px 10px rgba(0, 0, 0, 0.3);
	-webkit-transform: translateY(-3px);
	-moz-transform: translateY(-3px);
	-ms-transform: translateY(-3px);
	transform: translateY(-3px)
}

.footer-area .insta-feed {
	padding-top: 5px;
	margin: -2px;
	display: flex;
	flex-wrap: wrap
}

.footer-area .insta-feed li {
	width: 29.33%;
	margin: 5px
}

.footer-area .insta-feed li img {
	width: 100%
}

.footer-bottom-area .footer-border {
	border-top: 1px solid #e6e6e6;
}

.footer-area a {
	font-size: 14px;
}

.footer-bottom-area .list-inline-item:not(:last-child) {
	margin-right: 30px;
}

.footer-bottom-area .footer-copy-right p {
	font-weight: 300;
	line-height: 1;
	margin: 0;
	text-align: center;
}

.footer-bottom-area .footer-copy-right p a:hover {
	color: #fff
}

.footer-menu ul li {
	display: inline-block;
	margin-left: 36px
}

.footer-menu ul li:first-child {
	margin-left: 0px
}

/*Pagination*/
.pagination-area .page-item {
	margin: 0;
}

.pagination-area .page-item:first-child {
	margin: 0
}

.pagination-area .page-link {
	border: 0;
	font-size: 13px;
	box-shadow: none;
	outline: 0;
	color: #889097;
	background: #fff;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	text-align: center;
	line-height: 35px;
	padding: 0;
	margin-right: 10px;
}

.pagination-area .page-item.active .page-link {
	color: #fff !important;

}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
	border-radius: 50% !important;
	background: none;
}

.pagination li.active {
	background: #f2546a;
}

.pagination li.active a {
	color: #fff !important;
}

ul.ReactPaginate li {
	min-width: 40px !important;
	height: 40px !important;
	background: #fff;
	float: left;
	line-height: 40px;
	color: #889097;
	font-size: 14px;
	border-radius: 100%;
}

ul.ReactPaginate .previous {
	border-radius: 5px;
	background: #ddd;
	min-width: auto !important;
	padding: 0 10px;
}

ul.ReactPaginate .next {
	border-radius: 5px;
	background: #ddd;
	min-width: auto !important;
	padding: 0 10px;
}

/*Breadcrumb*/
.breadcrumb {
	display: inline-block;
	padding: 0;
	text-transform: capitalize;
	color: #6e6e6e;
	font-size: 0.875rem;
	background: none;
	margin: 0;
	border-radius: 0;
}

.breadcrumb span {
	position: relative;
	text-align: center;
	padding: 0 10px;
}

.breadcrumb span::before {
	content: "\e649";
	font-family: 'themify';
	display: inline-block;
	color: #101d35;
	font-size: 9px;
}

.breadcrumb span.no-arrow::before {
	content: none;
}

/*LOOP*/
.loop-list-style-1 .post-thumb {
	border-radius: 10px;
}

.loop-list-style-1 .post-thumb img {
	width: 150px;
	height: 150px;
	position: relative;
}

.loop-list-style-1 .post-thumb.post-thumb-big img {
	width: 250px;
	height: 250px;
}

/*CATEGORY PAGES*/
.archive-header h2 {
	font-size: 45px;
	position: relative;
}

.archive-header h2 span.post-count {
	font-size: 13px;
	font-weight: 500;
	position: absolute;
	background: #fff;
	display: inline-block;
	padding: 5px 20px;
	margin-left: 15px;
	border-radius: 30px;
	border: 1px solid #abd7ab;
	color: #98ca98;
}

.first-post {
	padding: 10px;
	background-color: white;
	border-radius: 10px;
	margin-bottom: 30px;
	/* border: 1px solid rgb(236, 235, 235);  */
	border-bottom: 1px solid rgb(236, 235, 235);
	;
}

.archive .loop-list-1 .first-post .img-hover-slide {
	min-height: 400px;
}

.animate-conner-box::after {
	content: " ";
	width: 45px;
	height: 45px;
	display: block;
	position: absolute;
	bottom: -45px;
	right: -45px;
	border-left: 45px solid #f2546a;
	border-bottom: 45px solid #fff;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.animate-conner:hover .animate-conner-box::after {
	bottom: 0 !important;
	right: 0 !important
}

/*SINGLE POST*/
.entry-header-1 h1 {
	max-width: 100%;
}

.entry-header-2 h1 {
	max-width: 80%;
	margin: 0 auto;
}

.overflow-hidden {
	overflow: hidden;
}

.font-weight-500 {
	font-weight: 500;
}

.single-sidebar-share {
	position: absolute;
	left: -100px;
}

.single-social-share a {
	background: #ffffff;
	width: 40px;
	height: 40px;
	margin-bottom: 10px;
	line-height: 44px;
	text-align: center;
	border-radius: 50%;
	font-size: 14px;
	box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.15), 0px 3px 8px -2px rgba(0, 0, 0, 0.15);
	display: block;
	font-weight: 500;
}

.single-thumnail .arrow-cover i {
	color: #fff;
}

.single-excerpt p {
	line-height: 1.5;
}

.entry-main-content p {
	line-height: 1.75;
}

.entry-main-content h2 {
	line-height: 1.25;
	margin-top: 30px;
	margin-bottom: 28px;
	font-size: 24px;
}



.entry-main-content figure {
	margin-bottom: 30px;
	margin-top: 30px;
}

.entry-bottom .tags a {
	display: inline-block;
	font-weight: normal;
	text-decoration: none;
	padding: 5px 20px;
	background-color: #fff;
	border: 1px solid #eee;
	margin-top: 2px;
	margin-bottom: 2px;
	margin-right: 5px;
	border-radius: 30px;
	font-size: 14px;
}

.related-posts .img-hover-slide {
	min-height: 200px;
}

.single-header-2 .single-thumnail {
	min-height: 540px;
	position: relative;
	background-size: cover;
	background-position: center center;
}

.single-header-2 .entry-header-1 {
	padding: 100px;
}

.single-header-3.single-header-2 .entry-header-1 {
	padding: 50px;
}

/*Author box*/
.author-bio {
	text-align: left;
	display: flex;
}

.author-bio .author-image img {
	border-radius: 50%;
	width: 90px;
	height: 90px;
}

.author-bio .author-image {
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
	width: 90px;
	height: 90px;
	margin-right: 40px;
}

.author-bio .author-info {
	flex-grow: 1;
}

.author-bio h3 {
	font-size: 22px;
	margin-bottom: 10px;
}

.author-bio h5 {
	font-size: 12px;
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: 500;
	color: var(--color-black);
}

.author-bio .author-description {
	margin-top: 15px;
	margin-bottom: 15px;
}

.author-bio .author-bio-link {
	display: inline-block;
	font-weight: normal;
	text-decoration: none;
	text-transform: lowercase;
	padding: 5px 20px;
	background-color: #fff;
	border: 1px solid #eee;
	margin-right: 20px;
	border-radius: 30px;
	font-size: 14px;
}

.author-bio .author-social {
	display: inline-block;
}

.author-bio .author-social-icons {
	margin: 0;
	padding: 0;
	list-style: none;
}

.author-bio .author-social-icons li {
	display: inline-block;
}

.author-bio .author-social-icons li:first-child a {
	margin-left: 0;
}

.author-bio .author-social-icons li a {
	text-align: center;
	font-size: 16px;
	margin-left: -1px;
	padding-top: 0;
	display: block;
	margin-left: 20px;
}

.author-bio .author-social-icons li a i {
	font-size: 14px;
}

.author .author-bio {
	margin: 0;
	padding: 50px;
	border: 0;
	background: #f4f5f9;
	border-radius: 5px;
}

/*comment*/
.comments-area {
	background: transparent;
	border-top: 1px solid #eee;
	padding: 45px 0;
	margin-top: 50px;
}

@media (max-width:414px) {
	.comments-area {
		padding: 50px 8px;
	}
}

.comments-area h4 {
	margin-bottom: 35px;
	color: #2a2a2a;
	font-size: 18px;
}

.comments-area h5 {
	font-size: 16px;
	margin-bottom: 0px;
}

.comments-area a {
	color: #2a2a2a;
}

.comments-area .comment-list {
	padding-bottom: 48px;
}

.comments-area .comment-list:last-child {
	padding-bottom: 0px;
}

.comments-area .comment-list.left-padding {
	padding-left: 25px;
}

.comments-area .thumb {
	margin-right: 20px;
}

.comments-area .thumb img {
	width: 70px;
	border-radius: 50%;
}

.comments-area .date {
	font-size: 14px;
	color: #999999;
	margin-bottom: 0;
	margin-left: 20px;
}

.comments-area .comment {
	margin-bottom: 10px;
	color: #777777;
	font-size: 15px;
}

.comments-area .btn-reply {
	background: #fff;
	color: #9babb6;
	padding: 5px 18px;
	font-size: 12px;
	display: block;
	font-weight: 400;
	border: 1px solid #eee;
	border-radius: 30px;
}

.comment-form {
	border-top: 1px solid #eee;
	padding-top: 45px;
	margin-top: 50px;
}

.comment-form .form-group {
	margin-bottom: 30px;
}

.comment-form h4 {
	margin-bottom: 40px;
	font-size: 18px;
	line-height: 22px;
	color: #2a2a2a;
}

.comment-form .name {
	padding-left: 0px;
}

@media (max-width:767px) {
	.comment-form .name {
		padding-right: 0px;
		margin-bottom: 1rem;
	}
}

.comment-form .email {
	padding-right: 0px;
}

@media (max-width:991px) {
	.comment-form .email {
		padding-left: 0px;
	}
}

.form-control {
	border: 1px solid #eee;
	border-radius: 5px;
	height: 48px;
	padding-left: 18px;
	font-size: 13px;
	background: transparent;
	background: #fff;
}

.form-control:focus {
	outline: 0;
	box-shadow: none;
}

.form-control::placeholder {
	font-weight: 300;
	color: #999999;
}

.form-control::placeholder {
	color: #777777;
}

.comment-form textarea {
	padding-top: 18px;
	border-radius: 5px;
	height: 100% !important;
	background: #fff;
}

.comment-form::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 13px;
	color: #777;
}

.comment-form::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 13px;
	color: #777;
}

.comment-form:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 13px;
	color: #777;
}

.comment-form:-moz-placeholder {
	/* Firefox 18- */
	font-size: 13px;
	color: #777;
}

.button-contactForm {
	background: #f2546a;
	color: #fff;
	border-color: #f2546a;
	padding: 12px 25px;
}

.button {
	display: inline-block;
	border: 1px solid transparent;
	font-size: 14px;
	font-weight: 500;
	padding: 12px 54px;
	border-radius: 4px;
	color: #fff;
	border: 1px solid #f2546a;
	text-transform: uppercase;
	background: #f2546a;
	cursor: pointer;
	transition: all 300ms linear 0s;
}

.button:hover {
	background: #010f22;
	border: 1px solid #010f22
}

/*post-share-social*/
figure .social-share,
.post-thumb .social-share {
	position: absolute;
	float: left;
	overflow: hidden;
	padding-left: 0;
	bottom: 10px;
	right: 0;
	margin: 0;
	z-index: 200;
}

.animate-conner .post-thumb .social-share {
	right: 35px;
}

figure .social-share li,
.post-thumb .social-share li {
	width: 100%;
	text-align: center;
	max-width: 0px;
	opacity: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	float: left;
	margin-left: 5px;
}

figure .social-share li:first-child,
.post-thumb .social-share li:first-child {
	max-width: 30px;
	opacity: 1;
	pointer-events: none;
}

figure .social-share li a,
.post-thumb .social-share li a {
	display: block;
	width: 30px;
	height: 30px;
	line-height: 32px;
	text-align: center;
	border-radius: 50%;
	font-size: 12px;
	color: #fff;
}

figure .social-share:hover li:nth-child(1),
.post-thumb .social-share:hover li:nth-child(1) {
	opacity: 0;
	max-width: 0;
}

figure .social-share:hover li,
.post-thumb .social-share:hover li {
	opacity: 1;
	max-width: 30px;
}

figure .social-share:hover li:last-child,
.post-thumb .social-share:hover li:last-child {
	margin-right: 15px;
}

.social-share {
	height: 0;
	transition-duration: 0.2s;
}

.bgcover:hover .social-share,
.post-thumb:hover .social-share {
	height: 30px;
	transition-duration: 0.2s;
}

.post-thumb .social-share li a {
	background: #007aff;
}

.post-thumb .social-share li a.fb {
	background: #3b5999;
}

.post-thumb .social-share li a.tw {
	background: #55acee;
}

.post-thumb .social-share li a.pt {
	background: #bd081c;
}

/*WP STYPE*/
.wp-block-separator {
	border-top: 1px solid #f4f5f9;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
}

.wp-block-image,
.wp-block-embed,
.wp-block-gallery {
	margin-bottom: 30px;
}

.blocks-gallery-grid,
.wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.blocks-gallery-grid,
.wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.blocks-gallery-grid.columns-3 .blocks-gallery-image,
.blocks-gallery-grid.columns-3 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
	width: calc((100% - 32px)/3);
	margin-right: 16px;
}

.blocks-gallery-grid.columns-3 .blocks-gallery-image:nth-of-type(3n),
.blocks-gallery-grid.columns-3 .blocks-gallery-item:nth-of-type(3n),
.wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
.wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n) {
	margin-right: 0;
}

.blocks-gallery-grid.columns-6 .blocks-gallery-image,
.blocks-gallery-grid.columns-6 .blocks-gallery-item,
.wp-block-gallery.columns-6 .blocks-gallery-image,
.wp-block-gallery.columns-6 .blocks-gallery-item {
	width: calc((100% - 30px)/6);
	margin-right: 15px;
}

.blocks-gallery-grid.columns-3 .blocks-gallery-image:nth-of-type(5n),
.blocks-gallery-grid.columns-3 .blocks-gallery-item:nth-of-type(5n),
.wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(5n),
.wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(5n) {
	margin-right: 0;
}

.blocks-gallery-grid.is-cropped .blocks-gallery-image a,
.blocks-gallery-grid.is-cropped .blocks-gallery-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-item a,
.blocks-gallery-grid.is-cropped .blocks-gallery-item img,
.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
	height: 100%;
	flex: 1;
	-o-object-fit: cover;
	object-fit: cover;
}

.wp-block-separator.is-style-dots {
	border: none;
	width: auto;
}

.wp-block-separator.is-style-dots {
	background: none !important;
	border: none;
	text-align: center;
	max-width: none;
	line-height: 1;
	height: auto;
}

.wp-block-separator.is-style-dots::before {
	content: "\00b7 \00b7 \00b7";
	color: currentColor;
	font-size: 20px;
	letter-spacing: 2em;
	padding-left: 2em;
	font-family: serif;
}

.wp-block-separator.is-style-dots {
	text-align: center;
	line-height: 1;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
	margin: 0 0 16px;
	padding: 30px;
	background: #f4f5f9;
	border-radius: 5px;
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
	font-size: 30px;
	line-height: 1.3;
	font-style: normal;
}

.wp-block-image {
	margin-bottom: 30px;
}

.entry-main-content figure.alignleft {
	margin-right: 30px;
	margin-top: 20px;
}

.wp-block-image .alignleft {
	float: left;
	margin-right: 1em;
}

.entry-main-content figure.alignright {
	margin-left: 30px;
	margin-top: 20px;
}

.wp-block-image .alignright {
	float: right;
	margin-left: 1em;
}

.wp-block-image .aligncenter>figcaption,
.wp-block-image .alignleft>figcaption,
.wp-block-image .alignright>figcaption,
.wp-block-image.is-resized>figcaption {
	display: table-caption;
	caption-side: bottom;
	width: 100%;
}

.wp-block-image figcaption {
	color: #6a6a6a;
	z-index: 1;
	bottom: 0;
	right: 0;
	line-height: 1.5;
	margin-bottom: 0;
	margin-top: 15px;
	text-align: center;
	font-size: 12px;
}

.tooltip-inner {
	font-size: 0.7em;
}

table {
	width: 100%;
	margin-bottom: 1.5rem;
	color: #212529;
	border-collapse: collapse;
}

.entry-main-content table td,
.entry-main-content table th,
.comment-content table td,
.comment-content table th {
	padding: 0.5em;
	border: 1px solid #d0d0d0;
}

.entry-main-content dd,
.single-comment dd {
	margin: 0 0 1.65em;
}

.entry-main-content ul {
	list-style: disc;
}

.entry-main-content ul li,
.comment-content ul li {
	list-style: disc inside;
	line-height: 2;
}

.entry-main-content ul li.blocks-gallery-item {
	list-style: none;
}

.entry-main-content li>ul,
.entry-main-content li>ol,
.comment-content li>ul,
.comment-content li>ol {
	padding-left: 2rem;
}

.entry-main-content ol {
	padding-left: 0;
}

ol li {
	list-style: decimal inside;
	width: 100%;
	line-height: 2;
}

pre {
	border: 1px solid #eee;
	font-size: 1em;
	line-height: 1.8;
	margin: 15px 0;
	max-width: 100%;
	overflow: auto;
	padding: 1.75em;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
	background: #fff;
	border-radius: 15px;
}

/*custom amine*/
.loader,
.bar {
	width: 100px;
	height: 20px;
}

.bar {
	position: absolute;
	display: flex;
	align-items: center;
}

.bar::before,
.bar::after {
	content: "";
	position: absolute;
	display: block;
	width: 10px;
	height: 10px;
	background: #3fa9f5;
	opacity: 0;
	border-radius: 10px;
	animation: slideleft 3s ease-in-out infinite;
}

.bar1::before {
	animation-delay: 0.00s
}

.bar1::after {
	animation-delay: 0.3s
}

.bar2::before {
	animation-delay: 0.60s
}

.bar2::after {
	animation-delay: 0.90s
}

.bar3::before {
	animation-delay: 1.20s
}

.bar3::after {
	animation-delay: 1.50s
}

@keyframes slideleft {
	10% {
		opacity: 0;
		transform: scale(0);
		right: 0
	}

	50% {
		opacity: 1;
		transform: scale(1)
	}

	90% {
		opacity: 0;
		transform: scale(0);
		right: 100%
	}
}

[data-loader='spinner'] {
	width: 35px;
	height: 35px;
	display: inline-block;
	-webkit-animation: spinner 1.2s infinite ease-in-out;
	-o-animation: spinner 1.2s infinite ease-in-out;
	animation: spinner 1.2s infinite ease-in-out;
	/* background: url(../imgs/favicon.svg); */
	box-shadow: 0 0 10px #fff;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		-ms-transform: perspective(120px) rotateX(0) rotateY(0);
		-o-transform: perspective(120px) rotateX(0) rotateY(0);
		transform: perspective(120px) rotateX(0) rotateY(0);
	}

	50% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		transform: perspective(120px) rotateX(-180deg) rotateY(0);
	}

	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
	}
}

@-moz-keyframes spinner {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		-ms-transform: perspective(120px) rotateX(0) rotateY(0);
		-o-transform: perspective(120px) rotateX(0) rotateY(0);
		transform: perspective(120px) rotateX(0) rotateY(0);
	}

	50% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		transform: perspective(120px) rotateX(-180deg) rotateY(0);
	}

	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
	}
}

@-o-keyframes spinner {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		-ms-transform: perspective(120px) rotateX(0) rotateY(0);
		-o-transform: perspective(120px) rotateX(0) rotateY(0);
		transform: perspective(120px) rotateX(0) rotateY(0);
	}

	50% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		transform: perspective(120px) rotateX(-180deg) rotateY(0);
	}

	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
	}
}

@keyframes spinner {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		-ms-transform: perspective(120px) rotateX(0) rotateY(0);
		-o-transform: perspective(120px) rotateX(0) rotateY(0);
		transform: perspective(120px) rotateX(0) rotateY(0);
	}

	50% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
		transform: perspective(120px) rotateX(-180deg) rotateY(0);
	}

	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		-o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
		transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
	}
}

@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.8);
	}

	100% {
		box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
	}
}

@keyframes shadow-pulse-big {
	0% {
		box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
	}

	100% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
}

@keyframes jump {
	0% {
		transform: translate3d(0, 50%, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.jump {
	transform-origin: 0;
	animation: jump .5s linear alternate infinite;
}

.header-style-2.header_searchs {
	position: fixed;
	right: 40%;
	top: 14px;
	z-index: 999;
}

/* .CatagoryHeader {
	margin-top: -70px;
	display: flex;
	justify-content: space-around;
	width: 100%;
	background-color: rgb(247, 247, 247);
	position: fixed;
	z-index: 999;
	box-shadow: 0px 6px 5px 1px #00000014;
} */

.CatagoryHeaderContent {
	display: flex;
	width: 60%;
	justify-content: space-around;
}

.large-pipe {
	font-size: 24px;
	/* Adjust the font size as needed */
}

.webDashBoardcategory_ul {
	background: #fff;
	margin-bottom: 15px;
	border: solid 1px #ededed;
}

.webDashBoardcategory_ul_li_title_images {
	background-color: rgb(239, 242, 243);
	border-radius: 9px;
	padding-left: 12px;
}

.webDashBoardcategory_Wise_Data {
	display: block;
}

.webDashBoardcategory_Wise_Data_div {
	/* padding: 20px 0 20px 20px; */
	background-color: white;
	border-top: solid 1px #ededed;
}

.webDashBoardcategory_Wise_Data_flex {
	display: flex;
}

.webDashBoardcategory_Wise_Data_flex_child1 {
	width: 80%;
}

.webDashBoardcategory_Wise_Data_flex_child2 {
	width: 20%;
}

.webDashBoardcategory_ul_li_link {
	background-color: rgb(228, 235, 238);
}

.visually-hidden {
	background-color: #f5f1f1;
	font-size: 30px;
	font-weight: 800;
	color: black;
	padding: 3px;
	border-radius: 12px;
}


.bootstrapDropdown {
	text-align: end;
}

.bootstrapDropdown.dropdown .btn:before {
	background: none;
}

.bootstrapDropdown.dropdown .btn {
	background: none !important;
	outline: none;
	box-shadow: none !important;
}

.dropdown-menu.show {
	display: block;
	border-top: none;
	border: 0.1px solid rgb(245, 239, 239);
}

@media (max-width:991px) {
	/* .bootstrapDropdown {
		position: fixed;
		right: 270px;
		top: -70px;
		z-index: 999;
	} */
}

.logo-img {
	height: 45px;
}

.img-hover-slide img {
	max-height: 350px;
	width: 100%;
	object-fit: cover;
	object-position: top;
}

.Carousel_Caption {
	color: #000000;
	font-weight: 900;
	margin: 0px;
	padding-bottom: 20px;
	font-family: 'Nunito', sans-serif;
	text-transform: capitalize;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2 !important;
}

.dropdown-menu.show {
	display: block;
	margin-top: -30px;
}

.catousel_title.carousel-caption {
	bottom: auto;
	top: 0%;
	left: 0%;
	text-align: left;
	background-image: linear-gradient(#0000008c, #00000000);
	padding: 20px 20px;
	border-radius: 10px;
	right: 0%;
}

.carousel_sliders {
	padding: 0px;
	margin-bottom: 30px;
}

.carousel_sliders .img-hover-slide img {
	border-radius: 10px;
}

.Carousel_Caption:hover {
	color: #f82e2e;
}

.carousel_cus.carousel.slide .carousel-indicators {
	display: none;
}

.bootstrapDrodown.dropdown .dropdown-toggle {
	padding: 4px;
	margin-top: 3px;
}


.carousel_sliders .img-hover-slide {
	padding: 20px;
	/* box-shadow: 0 0 30px 20px #00000036; */
	margin: 0;
	background: #fff;
	border: solid 1px #eee;
}

.carousel_sliders .img-hover-slide {
	padding: 20px;
	/* box-shadow: 0 0 30px 20px #00000036; */
	margin: 0;
	background: #fff;
	border: solid 1px #eee;
}


#video-player {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

@media (max-width:767px) {
	.sidebar-widget1 {
		display: none;
	}
}


.carousel_cus.carousel.slide .visually-hidden {
	display: none;
}

.carousel_cus.carousel.slide .carousel-control-prev-icon,
.carousel-control-next-icon {
	background-color: #000;
	padding: 30px !important;
	display: inline-block;
	background-size: 20px;
	border-radius: 100px;
	box-shadow: 0 0 30px 0 #0000008c;
}

.webDashBoardcategory_title_images {
	padding: 13px 20px;
	font-size: 20px;
	font-weight: 600;
	color: #000;
}


/* .webDashBoardcategory_Wise_Data_div:hover {
    background: #f7f8f9;
} */


.sidebar-right .post-thumb img {
	height: 220px;
	width: 100% !important;
	object-fit: cover;
	object-position: left top;
}

.main-container {
	margin-top: 20px;
}

.CatagoryHeaderContent .cat-item {
	position: relative;
}

.CatagoryHeaderContent .cat-item:after {
	content: '';
	position: absolute;
	border-right: solid 2px #aaaaaa;
	border-radius: 0;
	right: 0;
	top: 5px;
	bottom: 5px;
}

.d-flex {
	display: flex !important;
	padding: 2px;
	padding-top: 2px;
}


.post_boxes .post-title a:hover{
	color: #f04646;
}


.CatagoryHeaderContent .cat-item:last-child:after {
	display: none;
}

/* new css 06-12-2023 */
#top-head {
    background-color: #f8f8f8;
    color: #444;
    border-bottom: 1px solid #dadada;
    float: left;
    width: 100%;
	align-items: center;
	padding: 5px;
}

.con-info-list li {
    font-size: 14px;
    margin-right: 15px;
    display: inline-block;
    color: #444;
    font-weight: 400;
}

.con-info-list li i {
    color: #e53935;
    font-size: 16px;
    margin-right: 10px;
}
.top-head-social li {
    display: inline-block;
    padding: 0 12px 0 0px;
}
.top-head-social ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}


.navbar-brand {
	margin: 0px !important;
}


.navbar-brand img {
    width: 160px;
}


.nav_custome a {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 22px;
    color: gray !important;
    text-transform: uppercase;
    font-style: normal;
    display: flex;
    justify-content: space-around;
    margin-left: 30px;
}
.topstories_title {
    font-size: 14px;
    font-weight: bolder;
    width: max-content;
}


.position_bottom {
    position: absolute;
    bottom: 0;
    padding: 90px 30px 30px;
    width: 100%;
	background: linear-gradient(360deg, black, #ff424200);
}



.position_bottom .entry-meta span {
    font-size: 16px;
    font-weight: 400;
    color: #ffffffc9;
}

.position_bottom .entry-meta span a {
    color: #ffffffc9;
}
.position_bottom .post-title {
    font-weight: 700;
}

.position_bottom p {
    font-size: 16px;

    margin-bottom: 8px !important;
}
.mini_post.position_bottom .entry-meta span {
    font-size: 14px;
    font-weight: 400;
    color: #9f9999 !important;
    font-family: "Noto Sans", sans-serif;
}


.mini_post.position_bottom .post-title {
    font-weight: 700;
	line-height: normal;
    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    width: 100%;
	font-family: "Noto Sans", sans-serif;
}

.mini_post.position_bottom p {
    letter-spacing: 0.6px;
    margin: 0;
}


.post__banner.post-thumb.img-hover-scale {
    height: 500px;
}

.post__banner.post-thumb.img-hover-scale a img {
    height: 100%;
    object-fit: cover;
    width: 100% !important;
    object-position: left top;
}


.post_boxes {
    display: grid;
    grid-template-columns: auto;
    gap: 0px;
    align-items: center;
}

.post_boxes .post-thumb img {
    height: 200px;
}

.post_boxes .entry-meta span {
    font-size: 14px;
    font-weight: 400;
    color: #9f9999;
    padding-right: 10px;
	font-family: "Noto Sans", sans-serif;
}
.post_boxes .entry-meta span a {
    color: #9f9999;
}

.post_boxes .post-title {
    font-size: clamp(16px, 2vw, 20px);
    font-weight: 700;
    color: #5a5a5a;
    margin-top: 10px;
    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}
.post_boxes .post-title a {
    color: #3c3c3c; text-transform: capitalize;
}

.category_title  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #dc3545;
	margin: 0% 5px;
}


.logo_header {
    width: 100%;
    float: left;
    padding: 16px 0; background: #fff;
}


.post-aside-topstory.international_post .post__banner {
    height: 340px;
    object-fit: cover;
}

.post-aside-topstory.international_post .position_bottom {
    position: static;
    padding: 0 0px 0px;
    background: none;
}
.post-aside-topstory.international_post .position_bottom h5 a {
    color: #212529 !important;
}

.international_post .entry-meta .post-in {
    color: #212529 !important;
}


.post-aside-topstory.post-lifestyle .img-hover-scale {
    height: 240px;
    margin-bottom: 21px;
}


.morenews.sidebar-right .post_boxes .post-thumb img {
    height: 207px;
}

/* .morenews .post-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
	font-family: "Noto Sans", sans-serif;
} */

.footerheading {
    font-size: 20px;
    color: #000;
    font-weight: 700;
    border-bottom: solid 2px #000;
    width: fit-content;
    margin-bottom: 15px;
}

.textline {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; line-height: normal;
	font-family: Noto Sans, sans-serif;
}

.latestnews_update .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: #dc3545;
}

.bootstrapDropdown {
    margin-left: 30px;
	/* border: 1px solid rgb(209, 207, 207); */
    /* border-radius: 50px; */
}

.select_language .DropdownMenu {
    padding: 6px 12px;
    font-size: 18px;
    color: #888;
	margin: auto;
}


.select_language .bootstrapDropdown.dropdown button {
    padding: 0 14px;
    min-width: auto !important;
    height: 37px;
    border: solid 1px #ddd;
    border-radius: 100px;
    font-size: 13px;
}

.select_language .dropdown-item {
    display: block;
    width: 100%;
    padding: 4px 0;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}


.news_post_view img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: top left;
}
  .buscar-caja:hover > .buscar-txt 
  {
	width: 160px; 
	padding: 0 6px; 
  } 
  .buscar-caja:hover > .buscar-btn {
	background: white; 
	color: black; 
  } 
  
  .buscar-btn { 
	
	float: right; 
	width: 40px; 
	height: 40px; 
	border-radius: 50%; 
	
	display: flex; 
	justify-content: center; 
	align-items: center; 
	transition: 0.4s; 
	color: white; 
	cursor: pointer; 
  } 
  .buscar-btn > i 
  { 
	font-size: 14px; 
	color: gray;
  } 
  .buscar-txt {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: #333;
    font-size: 16px;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
    border-bottom: solid 1px #eee;
}
  .btn-primary{
	  background: #fff;
	  color: #CB356B;
	  border: none;
  }
  


.address-icon p span {
    background: #767676;
    padding: 0;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 24px;
}

.address-icon p strong {
    width: 100%;
    float: left;
}
.address-icon p {
    display: grid;
    gap: 8px;
    color: #fff;
    grid-template-columns: 60px 1fr;
}


ul#pills-tab li div a {
    position: relative;
    padding: 5px 10px; cursor: pointer;
}
ul#pills-tab li div a.active-category:after {
    position: absolute;
    content: "";
    bottom: -14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid red;
}

.active-category {
    background-color: rgba(255, 255, 255, 0);
    color: #df0303 !important;
}


.navtab-style .font-small {
    font-weight: 500;
}


ul.nav.nav-tabs.navtab-style .rounded-100 {
    border-radius: 100px;
    color: #fff;
    padding: 4px 12px !important;
}

ul.nav.nav-tabs.navtab-style .post-title {
    font-size: 22px;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
}
.post-title1{
	margin-bottom: 20px;
}

.topKhabre {
    font-size: 22px;
    font-weight: 700;
	font-family: 'Nunito', sans-serif;
}

ul.nav.nav-tabs.navtab-style .list-group-item-action {
    margin-top: 20px;
	float: left; width: 100%;
}
.activeLink{
	color: #df0303;
}
.Trending_Post_Details{
	margin-bottom: 20px;
	line-height: 1.5;
	font-family: "Noto Sans", sans-serif;
}
.Category_post-title{
	font-size: clamp(17px, 2vw, 27px);
    font-weight: 600;
    color: #313131;
    /* margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}
/* .Category_post-title .post-title:hover{
	color: #f82e2e !important;

} */
.Category_News_Title{
margin-bottom: 70px;
line-height: 1.5rem;
}
.Category_News_Title .post-title{
	padding-top: 5px;
	font-weight: 600;
}
.Category_News_Title .post-in{
	padding-top: 5px;
}

.Footer_Only_Title_Number{
	font-size: 3rem;
	margin-right: 20px;
	color: #e00606;
}
.Footer_Only_Title_Text {
    font-size: calc(14px + 5px);
}
.Footer_Only_Title_col{
	align-items: center;
	gap: 20px;
	background-color: white;
	margin-top: 20px;
	cursor: pointer;
	font-weight: 600;
}
.Footer_Only_Title{
background-color: white;
}
.SliderImagesTitle {
    padding: 20px 0;
    font-size: 27px;
    font-weight: 700;
    z-index: 111;
    /* background: linear-gradient(180deg, #000000b3, transparent); */
    float: left;
}

.SliderImagesTitle1 {
	display: -webkit-box;
	-webkit-line-clamp: 2; /* Limit to 2 lines */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal; /* Allow wrapping */
	width: 100%; /* Ensure proper width */
	max-height: 4em; /* Adjust based on font size */
  }
  
.SliderImagesTitle2 {
	display: -webkit-box;
	-webkit-line-clamp: 3; /* Limit to 2 lines */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal; /* Allow wrapping */
	width: 100%; /* Ensure proper width */
	max-height: 5em; /* Adjust based on font size */
  }
  

/* .thumb_images:after {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgb(2 0 36 / 14%) 0%, rgb(0 212 255 / 0%) 100%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
} */
.single-excerpt ul{
gap: 15px;
/* text-align: left;
 */
 /* margin-left: 75%; */
}
.single-excerpt ul li{
	font-size: clamp(16px, 2vw, 19px);
	cursor: pointer;
}


.latest-post.newupdates .post-title {
    font-size: 15px;
}
.latest-post.newupdates .font-small {
    padding: 0 !important;
}

.latest-post.newupdates .post_boxes {
    display: grid;
    gap: 0px;
    align-items: center;
    grid-auto-flow: revert;
    grid-template-columns: auto;
}

.post-content.media-body {
    padding: 0px;
}

.Footer_Only_Title_col a {
    display: flex;
    align-items: center;
}

.react-slideshow-container {
    flex-direction: column;
    top: 0;
}
.react-slideshow-container .nav:first-of-type {
    left: 10px;
    top: 110px;
}
.react-slideshow-container .nav:last-of-type {
    right: 10px;
    top: 110px;
}

.react-slideshow-container .nav:first-of-type svg {
    width: 13px;
}
.react-slideshow-container .nav:last-of-type svg {
    width: 13px;
}

a.description_title h2, description_title h1, description_title h3, description_title h4, description_title h6, description_title p {
    font-size: 16px;
    font-weight: 400 !important;
    color: #333 !important;
    font-family: "Noto Sans", sans-serif;
    line-height: inherit;
}
a.description_title h2 strong {
    font-weight: 400 !important;
}

.nav_custome {
    box-shadow: 0px 11px 9px -14px #000000d6;
}
.sliderdescription a{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.pagination-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	gap: 10px;
  }
  
  .pagination-buttons button {
	background-color: #dc3545 !important; /* Red background */
	color: white !important; /* White text */
	border: none;
	padding: 8px 16px;
	font-size: 14px;
	border-radius: 5px;
	cursor: pointer;
	transition: background 0.3s ease-in-out;
  }
  
  .pagination-buttons button:hover {
	background-color: #c82333 !important; /* Darker red on hover */
  }
  
  .pagination-buttons button:disabled {
	background-color: #e0a0a7 !important; /* Lighter red for disabled */
	cursor: not-allowed;
  }
  
  .pagination-buttons span {
	font-size: 16px;
	font-weight: bold;
	color: #333;
  }
  

  .international_sec .post_boxes .post-thumb img {
    height: 160px;
}